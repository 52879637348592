'use strict';

angular.module('cpformplastApp.inventory')
  .config(function ($stateProvider) {
    $stateProvider
      .state('inventory/locations', {
        url: '/inventory/locations',
        templateUrl: 'app/inventory/locations/locations.html',
        controller: 'locationsController',
        authenticate: ['shipper','admin','receptionist', 'management_dept', 'logistic_dept']
      })
      .state('inventory/locations/location-creation', {
        url: '/inventory/locations/location-creation/:locationId',
        templateUrl: 'app/inventory/locations/location-creation/location-creation.html',
        controller: 'locationCreationController',
        authenticate: ['shipper','admin','receptionist', 'management_dept', 'logistic_dept']
      })
      .state('inventory/raw-material', {
        url: '/inventory/raw-material',
        templateUrl: 'app/inventory/raw-material/raw-material.html',
        controller: 'rawMaterialController',
        authenticate: ['shipper','admin','receptionist', 'management_dept', 'logistic_dept']
      })
      .state('inventory/finished-product', {
        url: '/inventory/finished-product',
        templateUrl: 'app/inventory/finished-product/finished-product.html',
        controller: 'finishedProductController',
        authenticate: ['shipper','admin','receptionist', 'management_dept', 'logistic_dept', "salesmen_dept"]
      })
      .state('inventory/grid', {
        url: '/inventory/grid/:search/:filter/:rawMaterialId/:finishedProductId',
        templateUrl: 'app/inventory/grid/grid.html',
        controller: 'gridController',
        authenticate: ['shipper','admin','receptionist', 'management_dept', 'logistic_dept', 'salesmen_dept']
        //TODO peut-être enlever salesman_dept de la route grid
      });
  });
